<template>
  <v-card :disabled="loading" elevation="0">
    <v-toolbar
      color="rgb(238, 240, 248)"
      style="box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;"
      height="64"
    >
      <v-btn icon dark color="primary" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-if="!isnew" class="text-primary">
        {{ getTranslation(item).name }}
      </v-toolbar-title>
      <v-toolbar-title v-else class="text-primary">
        Add New Type
      </v-toolbar-title>
      <v-spacer />
      <template v-if="!isnew">
        <v-btn
          class="mx-2"
          small
          :rounded="doedit"
          :fab="!doedit"
          height="40px"
          elevation="0"
          color="rgba(165, 203, 239, 0.20)"
          @click="doedit = !doedit"
        >
          <unicon
            style="margin-right: auto;margin-left: auto;"
            name="edit-alt"
            fill="#0971CE"
            height="20"
          ></unicon>
          <span class="text-primary" v-if="doedit">editing active</span>
        </v-btn>
        <v-btn
          @click="deletedialg = true"
          small
          fab
          elevation="0"
          color="rgba(239, 165, 179, 0.25)"
        >
          <unicon
            style="margin-right: auto;margin-left: auto;"
            name="trash-alt"
            fill="#f64e60"
            height="20"
          ></unicon>
        </v-btn>
      </template>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <perfect-scrollbar
      class="scroll"
      style="position: relative"
      :style="
        loading ? 'height:calc(100vh - 132px);' : 'height:calc(100vh - 128px);'
      "
    >
      <v-card-text class="p-0 m-0">
        <v-list-item class="p-0 mx-5 mt-7 mb-3">
          <v-avatar v-if="newiconpreview" color="#f2f7fd" size="60">
            <v-img width="30" height="30" contain :src="newiconpreview" />
          </v-avatar>
          <v-avatar
            v-else-if="item.icon && item.icon.data"
            color="#f2f7fd"
            size="60"
          >
            <v-img
              :src="$root.assetsBaseURL + '' + item.icon.data.asset_url"
              contain
              height="27"
              width="27"
            ></v-img>
          </v-avatar>
          <v-avatar v-else color="#f2f7fd" size="60">
            <div
              style="text-transform: uppercase;width:100%"
              class="primary--text font-size-h1"
            >
              D
            </div>
          </v-avatar>

          <v-list-item-content class="mx-3">
            <v-list-item-title v-if="newiconpreview" style="font-size:1.3rem">
              {{ newicon.name }}
            </v-list-item-title>
            <v-list-item-title v-else-if="item.icon" style="font-size:1.3rem">
              {{ item.icon.filename_download }}
            </v-list-item-title>
            <v-list-item-title v-else style="font-size:1.3rem">
              No Icon yet
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            style="display: flex;flex-direction: row; min-height:40px"
          >
            <v-btn
              v-show="doedit || isnew"
              class="mx-2"
              small
              fab
              height="40px"
              elevation="0"
              color="rgba(165, 203, 239, 0.20)"
              @click="preparefile"
            >
              <unicon
                style="margin-right: auto;margin-left: auto;"
                name="upload-alt"
                fill="#0971CE"
                height="20"
              ></unicon>
            </v-btn>
            <input
              type="file"
              accept="image/svg+xml"
              class="d-none"
              ref="uploader"
              @change="processfile"
            />
            <template v-if="doedit || isnew">
              <v-btn
                @click="removeicon"
                v-show="item.icon || newiconpreview"
                small
                fab
                elevation="0"
                color="rgba(239, 165, 179, 0.25)"
              >
                <v-icon v-if="newiconpreview" color="#f64e60">mdi-close</v-icon>
                <unicon
                  v-else-if="item.icon"
                  style="margin-right: auto;margin-left: auto;"
                  name="trash-alt"
                  fill="#f64e60"
                  height="20"
                ></unicon>
              </v-btn>
            </template>
          </v-list-item-action>
        </v-list-item>
        <v-divider class="mx-5" />
        <div class="mx-5">
          <div
            class="mb-3"
            style="width:150px; font-size:1.2rem; font-weight:500; color:#757575;text-transform: capitalize;"
          >
            Name
          </div>
          <v-tabs v-model="panel" class="mb-4">
            <v-tab v-for="item in lang" :key="item.id">
              <div class="display:flex; flex-direction:row">
                <v-avatar class="pushxl" size="25">
                  <img
                    :src="
                      $root.assetsBaseURL +
                        '/public/doctory/assets/' +
                        item.icon
                    "
                    alt=""
                  /> </v-avatar
                >{{ item.name }}
              </div>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="panel">
            <v-tab-item v-for="item in lang" :key="item.id">
              <v-text-field
                v-model="item.translation.name"
                label="name"
                hide-details
                class="mb-4"
                :outlined="doedit || isnew"
                :disabled="!doedit && !isnew"
              />
            </v-tab-item>
          </v-tabs-items>
          <v-divider />
          <div
            class="mb-3"
            style="width:150px; font-size:1.2rem; font-weight:500; color:#757575;text-transform: capitalize;"
          >
            Code
          </div>
          <v-text-field
            class="mb-4"
            :outlined="doedit || isnew"
            :disabled="!doedit && !isnew"
            v-model="item.code"
            label="Code"
            hide-details
          />
        </div>
      </v-card-text>
    </perfect-scrollbar>
    <v-card-actions
      v-if="doedit || isnew"
      style="height:64px;display:flex;width:100%;justify-content: center;align-items: center;"
    >
      <v-btn
        width="48%"
        dark
        elevation="0"
        large
        rounded
        color=" red"
        @click="isnew ? close() : (doedit = !doedit)"
      >
        {{ $t("QUES.cancel") }}
      </v-btn>
      <v-btn
        @click="takeaction"
        width="48%"
        elevation="0"
        rounded
        large
        color="primary"
      >
        Save
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="deletedialg" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Delete {{ getTranslation(item).name }}?
        </v-card-title>

        <v-card-text>
          Deleting the History Type is an irreversable action, are you sure you
          want to proceed?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="deletedialg = false">
            Cancel
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="
              deletedialg = false;
              deleteItem();
            "
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
//import ApiService from "@/core/services/api.service";
// import { mapActions } from "vuex";

export default {
  props: [],
  name: "HistoryTypeForm",
  data() {
    return {
      item: {},
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      doedit: false,
      newicon: null,
      newiconpreview: null,
      panel: 0,
      lang: [],
      isnew: false,
      deletedialg: false
    };
  },
  watch: {
    isnew: function(val) {
      if (val) {
        this.item.translation = [];
        this.item.type = "";
        this.item.code = "";
      }
    }
  },
  methods: {
    getTranslation(d) {
      if (d.translation && d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
    isNew(val) {
      this.isnew = val;
    },
    setData(e) {
      this.item = e;
      for (let x = 0; x < this.lang.length; x++) {
        let i = this.item.translation.findIndex(j => {
          return j.lang == this.lang[x].code;
        });
        if (i >= 0) {
          this.lang[x].translation = this.item.translation[i];
        } else {
          this.lang[x].translation = {};
        }
      }
    },
    removeData() {
      this.item = {};
      this.removeicon();
      this.lang.forEach(i => {
        i.translation = {};
      });
    },
    preparefile() {
      this.$refs.uploader.click();
    },
    processfile(e) {
      this.newicon = e.target.files[0];
      this.newiconpreview = URL.createObjectURL(e.target.files[0]);
    },
    removeicon() {
      if (this.newicon) {
        this.newicon = null;
        this.newiconpreview = null;
      }
    },
    close() {
      this.$emit("close");
    },
    emitChange(type, msg) {
      this.$emit("update", { type: type, msg: msg });
    },
    setlanguages(e) {
      e.translation = { name: "" };
      this.lang.push(e);
    },
    async takeaction() {
      this.loading = true;
      let payload = {};
      let translation = [];
      this.lang.forEach(item => {
        translation.push(item.translation);
        translation[translation.length - 1].lang = item.code;
      });

      payload.translation = translation;
      // console.log("lang is in action after consildation", payload);

      if (this.newicon) {
        var formData = new FormData();
        formData.append("data", this.newicon);
        const { data } = await this.$api.simplePostFile("files", formData);
        //// console.log("file upload result id",data.data.id)
        payload.icon = data.data.id;
      }
      payload.type = translation[0].name;
      payload.code = this.item.code;
      // console.log("data to push", payload);
      if (this.isnew)
        await this.$historyTypes.create(payload).then(() => {
          this.emitChange(
            "create",
            `History Type ${payload.name} created successfully`
          );
          this.close();
        });
      else
        await this.$historyTypes.update(this.item.id, payload).then(() => {
          this.emitChange(
            "update",
            `History Type ${payload.name} updated successfully`
          );
        });
      this.loading = false;
    },
    async deleteItem() {
      this.loading = true;
      let flag = await this.$historyTypes.isInUse(this.item.id);
      if (flag) {
        this.emitChange(
          "error",
          `Cannot Delete! History Type ${
            this.getTranslation(this.item).name
          } is in use`
        );
        this.loading = false;
        return;
      }
      await this.$historyTypes
        .deleteTranslation(this.item.translation)
        .then(async () => {
          await this.$historyTypes.deleteItem(this.item.id).then(() => {
            this.emitChange("delete", `History Type deleted successfully`);
          });
        });

      this.loading = false;
      this.close();
    }
  },

  created() {
    this.$gun
      .get("settings")
      .get("langs")
      .map()
      .on(this.setlanguages);
  },
  mounted() {}
};
</script>
<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
