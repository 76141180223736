<template>
  <v-card :disabled="loading" elevation="0">
    <v-toolbar
      color="rgb(238, 240, 248)"
      style="box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px"
      height="64"
    >
      <v-btn icon dark color="primary" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-if="!isnew" class="text-primary">
        {{ getTranslation(item).name }}
      </v-toolbar-title>
      <v-toolbar-title v-else class="text-primary">
        Add New Type
      </v-toolbar-title>
      <v-spacer />
      <template v-if="!isnew">
        <v-btn
          class="mx-2"
          small
          :rounded="doedit"
          :fab="!doedit"
          height="40px"
          elevation="0"
          color="rgba(165, 203, 239, 0.20)"
          @click="doedit = !doedit"
        >
          <unicon
            style="margin-right: auto; margin-left: auto"
            name="edit-alt"
            fill="#0971CE"
            height="20"
          ></unicon>
          <span class="text-primary" v-if="doedit">editing active</span>
        </v-btn>
        <v-btn
          @click="deletedialg = true"
          small
          fab
          elevation="0"
          color="rgba(239, 165, 179, 0.25)"
        >
          <unicon
            style="margin-right: auto; margin-left: auto"
            name="trash-alt"
            fill="#f64e60"
            height="20"
          ></unicon>
        </v-btn>
      </template>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <perfect-scrollbar
      class="scroll"
      style="position: relative"
      :style="
        loading ? 'height:calc(100vh - 132px);' : 'height:calc(100vh - 128px);'
      "
    >
      <v-card-text class="p-0 m-0">
        <v-list-item v-if="!isnew" class="p-0 mx-10 mt-7 mb-3">
          <v-avatar color="#f2f7fd" size="60">
            <v-img
              v-if="item.avatar != null"
              :src="$root.assetsBaseURL + '' + item.avatar.data.asset_url"
              contain
              height="27"
              width="27"
            ></v-img>
            <v-img
              v-else-if="item.icon != null && item.icon.data"
              :src="$root.assetsBaseURL + '' + item.icon.data.asset_url"
              contain
              height="27"
              width="27"
            ></v-img>
            <v-icon v-else-if="item.icon" color="primary">{{
              item.icon
            }}</v-icon>
            <div
              v-else
              style="text-transform: uppercase; width: 100%"
              class="primary--text font-size-h1"
            >
              P
            </div>
          </v-avatar>
          <v-list-item-content class="mx-3">
            <v-list-item-title style="font-size: 1.3rem">
              {{ getTranslation(item).name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="!isnew" class="mx-5" />
        <div class="mx-5">
          <div class="mt-7" v-if="isnew">
            <div
              class="mb-3"
              style="
                width: 150px;
                font-size: 1.2rem;
                font-weight: 500;
                color: #757575;
                text-transform: capitalize;
              "
            >
              Icon
            </div>
            <v-text-field
              v-model="item.icon"
              label="Icon"
              hide-details
              class="mb-4"
              :outlined="doedit || isnew"
              :disabled="!doedit && !isnew"
            />
            <v-divider />
          </div>
          <div
            class="mb-3"
            style="
              width: 150px;
              font-size: 1.2rem;
              font-weight: 500;
              color: #757575;
              text-transform: capitalize;
            "
          >
            Name
          </div>
          <v-tabs v-model="panel" class="mb-4">
            <v-tab v-for="item in lang" :key="item.id">
              <div class="display:flex; flex-direction:row">
                <v-avatar class="pushxl" size="25">
                  <img
                    :src="
                      $root.assetsBaseURL +
                        '/public/doctory/assets/' +
                        item.icon
                    "
                    alt=""
                  /> </v-avatar
                >{{ item.name }}
              </div>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="panel">
            <v-tab-item v-for="item in lang" :key="item.id">
              <v-text-field
                v-model="item.translation.name"
                label="name"
                hide-details
                class="mb-4"
                :outlined="doedit || isnew"
                :disabled="!doedit && !isnew"
              />
            </v-tab-item>
          </v-tabs-items>
          <v-divider />
          <div
            style="display: flex; flex-direction: row; align-items: center"
            class="mb-3"
          >
            <div
              style="
                width: 100px;
                font-size: 1.2rem;
                font-weight: 500;
                color: #757575;
                text-transform: capitalize;
              "
            >
              Avilable For
            </div>
            <v-checkbox
              v-model="item.active_for"
              label="Video"
              value="video"
              hide-details
              class="my-0 p-0 pushxl"
            ></v-checkbox>
            <v-checkbox
              v-model="item.active_for"
              label="Clinic"
              value="clinic"
              class="my-0 p-0"
              hide-details
            ></v-checkbox>
          </div>
          <v-divider />
          <div>
            <div
              style="
                width: 150px;
                font-size: 1.2rem;
                font-weight: 500;
                color: #757575;
                text-transform: capitalize;
              "
            >
              Payment Details
            </div>
            <v-expansion-panels
              v-model="selectedDetail"
              v-if="bank_details"
              mandatory
              accordion
            >
              <v-expansion-panel
                v-for="(detail, index) in bank_details.data"
                :key="detail.id"
              >
                <v-expansion-panel-header>
                  {{ detail.bank_name }} {{ detail.account_name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-5">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    "
                  >
                    <div style="flex-grow: 1" class="pushxl">
                      <v-text-field
                        outlined
                        v-model="detail.bank_name"
                        label="Bank Name"
                      />
                      <v-text-field
                        outlined
                        v-model="detail.iban"
                        label="IBAN or Account #"
                      />
                      <v-text-field
                        outlined
                        v-model="detail.account_name"
                        label="Account Holder Name"
                      />
                    </div>
                    <v-btn
                      small
                      fab
                      elevation="0"
                      color="rgba(239, 165, 179, 0.25)"
                      @click="deleteBankDetail(index)"
                    >
                      <unicon
                        style="margin-right: auto; margin-left: auto"
                        name="trash-alt"
                        fill="#f64e60"
                        height="20"
                      ></unicon>
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="px-3 mt-3">
              <v-btn block rounded color="primary" @click="addPayDetail">
                Add Payment Detail
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
    </perfect-scrollbar>
    <v-card-actions
      v-if="doedit || isnew"
      style="
        height: 64px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
      "
    >
      <v-btn
        width="48%"
        dark
        elevation="0"
        large
        rounded
        color=" red"
        @click="isnew ? close() : (doedit = !doedit)"
      >
        {{ $t("QUES.cancel") }}
      </v-btn>
      <v-btn
        @click="takeaction"
        width="48%"
        elevation="0"
        rounded
        large
        color="primary"
      >
        Save
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="deletedialg" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Delete {{ getTranslation(item).name }}?
        </v-card-title>

        <v-card-text>
          Deleting the Payment Method is an irreversable action, are you sure
          you want to proceed?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="deletedialg = false">
            Cancel
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="
              deletedialg = false;
              deleteItem();
            "
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
//import ApiService from "@/core/services/api.service";
// import { mapActions } from "vuex";

export default {
  props: [],
  name: "PaymentTypeForm",
  data() {
    return {
      item: {},
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      doedit: false,
      panel: 0,
      lang: [],
      selectedDetail: 0,
      isnew: false,
      deletedialg: false,
      bank_details: { data: [] }
    };
  },
  watch: {
    isnew: function(val) {
      if (val) {
        this.item.translation = [];
        this.item.icon = "";
        this.item.code = "";
        this.item.name = "";
        this.item.bank_details = { data: [] };
        this.item.active_for = [];
      }
    },
    item: function(newItem) {
      // console.log("new item alert", newItem)
      if (newItem && newItem.bank_details) {
        newItem.bank_details.data.forEach(el => {
          this.bank_details.data.push(el);
        });
      }
    }
  },
  methods: {
    deleteBankDetail(index) {
      this.bank_details.data.splice(index, 1);
    },
    addPayDetail() {
      // console.log("adding payment detail")
      if (this.item.bank_details) {
        // console.log("pushing empty object")
        this.bank_details.data.push({
          bank_name: "",
          iban: "",
          account_name: ""
        });
        // console.log("account details are:",this.bank_details )
        this.selectedDetail = this.bank_details.data.length - 1;
      } else {
        // console.log("creating bank details")
        this.bank_details = {
          data: [{ bank_name: "", iban: "", account_name: "" }]
        };
        // console.log("account details are:",this.bank_details )
      }
    },
    getTranslation(d) {
      if (d.translation && d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
    isNew(val) {
      this.isnew = val;
    },
    setData(e) {
      this.item = e;
      for (let x = 0; x < this.lang.length; x++) {
        let i = this.item.translation.findIndex(j => {
          return j.lang == this.lang[x].code;
        });
        if (i >= 0) {
          this.lang[x].translation = this.item.translation[i];
        } else {
          this.lang[x].translation = {};
        }
      }
    },
    removeData() {
      this.item = {};
      this.bank_details = { data: [] };
      this.lang.forEach(i => {
        i.translation = {};
      });
    },

    close() {
      this.$emit("close");
    },
    emitChange(type, msg) {
      this.$emit("update", { type: type, msg: msg });
    },
    setlanguages(e) {
      e.translation = { name: "", description: "" };
      this.lang.push(e);
    },
    async takeaction() {
      this.loading = true;
      let payload = {};
      let translation = [];
      this.lang.forEach(item => {
        translation.push(item.translation);
        translation[translation.length - 1].lang = item.code;
      });

      payload.translation = translation;
      // console.log("lang is in action after consildation", payload);
      payload.icon = this.item.icon;
      const id = translation.findIndex(i => {
        return i.lang === "en";
      });
      payload.name = translation[id].name;
      payload.code = payload.name.substr(0, 3).toUpperCase();
      payload.active_for = this.item.active_for;
      payload.status = "enabled";
      payload.bank_details = this.bank_details;
      // console.log("data to push", payload);
      if (this.isnew)
        await this.$payments.createItemType(payload).then(() => {
          this.emitChange(
            "create",
            `Payment Method ${payload.name} created successfully`
          );
          this.close();
        });
      else
        await this.$payments.updateItemType(this.item.id, payload).then(() => {
          this.emitChange(
            "update",
            `Payment Method ${payload.name} updated successfully`
          );
        });
      this.loading = false;
    },
    async deleteItem() {
      this.loading = true;
      let flag = await this.$payments.isTypeInUse(this.item.id);
      if (flag) {
        this.emitChange(
          "error",
          `Cannot Delete! Payment Method ${
            this.getTranslation(this.item).name
          } is in use`
        );
        this.loading = false;
        return;
      }
      await this.$payments
        .deleteTypeTranslation(this.item.translation)
        .then(async () => {
          await this.$payments.deleteItemType(this.item.id).then(() => {
            this.emitChange("delete", `Payment Method deleted successfully`);
          });
        });

      this.loading = false;
      this.close();
    }
  },

  created() {
    this.$gun
      .get("settings")
      .get("langs")
      .map()
      .on(this.setlanguages);
  },
  mounted() {}
};
</script>
<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
.theme--light.v-label {
  margin: 0;
}
</style>
