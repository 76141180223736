<template>
  <v-card flat>
    <v-toolbar flat>
      <v-card-title>
        General Settings
      </v-card-title>
      <v-spacer />

      <v-btn
        elevation="0"
        rounded
        dense
        :loading="loading"
        :disabled="loading"
        large
        color="primary"
        @click="updateSettings"
      >
        Save
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-list v-if="settings && structure">
        <v-list-item v-for="item in filteredStructure" :key="item.id">
          <v-list-item-content>
            <div style="display:flex; flex-direction:row;align-items: center;">
              <div
                style="width:150px; font-size:1.2rem; font-weight:500; color:#757575;text-transform: capitalize;"
              >
                {{ item.field.replace("_", " ") }}
              </div>

              <v-switch
                hide-details
                v-if="item.type == 'boolean' && item.field == 'payment_env'"
                class="my-0 p-0 mx-3"
                v-model="settings[item.field]"
                inset
                :label="settings[item.field] ? 'Live' : 'Test'"
              ></v-switch>
              <v-switch
                hide-details
                v-else-if="item.type == 'boolean'"
                class="my-0 p-0 mx-3"
                v-model="settings[item.field]"
                inset
                :label="settings[item.field] ? 'Enabled' : 'Disabled'"
              ></v-switch>
              <v-text-field
                v-else-if="item.type == 'integer' || item.type == 'string'"
                hide-details
                dense
                outlined
                v-model="settings[item.field]"
                :suffix="returnsuffix(item.options.iconLeft)"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div></div>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  components: {},
  data() {
    return {
      settings: {},
      // settings: [],
      loading: false,
      test: {
        handling_fee: "Fees",
        tax: "Tax",
        currency: "Currency",
        call_duration: "Call Duration"
      },
      item: {},
      openEditDialog: false,
      structure: null,
      fromgun: null
    };
  },
  methods: {
    // unmount() {
    //   this.item = {};
    //   this.openEditDialog = false;
    // },

    async updateSettings() {
      /*this.$gun
        .get("settings")
        .get("system")
        .put(this.settings);*/
      // console.log("updating this",this.settings)
      this.loading = true;
      this.$db
        .getlocaldb()
        .put(this.settings)
        .then(data => {
          // console.log("did put in pouch",data);
          this.settings["_rev"] = data.rev;
          this.loading = false;
        });
      let payload = {};
      this.filteredStructure.forEach(item => {
        payload[item.field] = this.settings[item.field];
      });

      ApiService.update("items/settings", this.settings.id, payload).then(
        data => {
          this.loading = false;
          // console.log("data from system update", data);
          if (data.status == 200) {
            this.$emit("updated", "system_settings");
          }
        }
      );
    },
    async getSettings() {
      try {
        /*const { data } = await ApiService.get("/items/settings");
        // console.log("settings are", data.data);
        this.settings = data.data[0];
        this.settings['_id']='settings'
        // console.log("settings are",this.settings);
        //this.$db.getlocaldb().put(this.settings);
        this.recordId = data.data[0].id;
        //this.$gun.get('settings').get('system').put(this.settings);
        */
        // this.settings = data.data;
        this.$db
          .getlocaldb()
          .get("settings")
          .then(data => {
            this.settings = data;
          });
      } catch (error) {
        // console.log(error);
      }
    },
    async forceSettings() {
      const { data } = await ApiService.get("/items/settings");

      let _rev = this.settings["_rev"];
      this.settings = data.data[0];
      this.settings["_id"] = "settings";
      this.seetings["_rev"] = _rev;
      this.$db.getlocaldb().put(this.settings);
    },
    returnsuffix(type) {
      switch (type.toLowerCase()) {
        case "timer": {
          return "mintues";
        }
        case "plus_one": {
          return "%";
        }
        case "money": {
          return this.settings.currency;
        }
        default:
          return "";
      }
    },
    editItem(key) {
      // console.log({ item: key });
      this.item = {};
      this.item[key] = this.settings[key];
      // this.formTitle = 'Edit User';
      this.openEditDialog = true;
    },

    async getFields() {
      try {
        const { data } = await ApiService.get(
          "/fields/settings?fields=field,type"
        );
        // console.log("data fields", data.data);
        this.structure = data.data;
      } catch (error) {
        // console.log({ error });
      }
    },
    setGun(settings) {
      // console.log("update", settings);
      this.settings = settings;
    }
  },
  mounted() {
    /* this.$gun
      .get("settings")
      .get("system")
      .on(this.setGun); */

    this.getSettings();
    this.getFields();
    this.$root.title = "Manage System Settings";
  },

  computed: {
    /*filteredSettings: function() {
      return this.settings.filter((i) => {
        return (
          i.field != "id" &&
          i.field != "owner" &&
          i.field != "created_on" &&
          i.field != "modified_on" &&
          i.field != "modified_by"
        );
      });
    },*/
    filteredStructure: function() {
      return this.structure.filter(i => {
        return (
          i.field != "id" &&
          i.field != "owner" &&
          i.field != "created_on" &&
          i.field != "modified_on" &&
          i.field != "modified_by"
        );
      });
    }
  }
};
</script>

<style></style>
