<template>
  <v-card flat style="background-color:transparent !important">
    <v-toolbar flat>
      <v-card-title style="background-color:transparent !important">
        System Languages
      </v-card-title>
      <v-spacer />
      <v-btn
        elevation="0"
        rounded
        dense
        large
        color="primary"
        class="pushx"
        @click="$util.setLanguages()"
      >
        Fetch Languages
      </v-btn>
    </v-toolbar>
    <v-card-text style="background-color:transparent !important">
      <v-list
        v-if="langs.length > 0"
        style=" background-color:transparent !important;"
      >
        <v-list-item-group
          color="primary"
          style=" background-color:transparent !important;    
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;"
        >
          <v-list-item
            style="max-width: 24% !important;"
            class="listitem mb-2 pushx"
            three-line
            v-for="item in langs"
            :key="item.id"
          >
            <v-list-item-avatar class="listitemicon" size="50" color="#f2f7fd">
              <v-img
                :src="`${$util.asseturl}${item.icon}`"
                height="20"
                width="20"
                contain
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action> </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-snackbar v-model="snackbar" timeout="5000">
      {{ msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      langs: [],
      snackbar: false,
      msg: ""
    };
  },

  methods: {
    processupdate(e) {
      this.msg = e.msg;
      this.snackbar = true;
    }
  },
  mounted() {
    this.langs = this.$util.getLanguages();
    // console.log("langs loaded",this.langs)
    this.$root.title = "Manage Languages";
  },
  computed: {}
};
</script>
<style scoped>
.theme--light.v-list-item:hover::before {
  border-radius: 16px !important;
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  border-radius: 16px !important;
}
</style>
